<template>
  <div>
    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <p
          class="display-1 font-weight-medium text-center text-md-left text-blue-grey lighten-2"
        >
          Información del estudiante
        </p>
      </div>
      <div class="card-body pt-4">
        <!-- begin::Student Information Card-->
        <v-sheet elevation="2" class="px-10 pb-10 rounded-lg mb-8">
          <div>
            <v-row>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row
                  class="d-flex flex-column-reverse flex-sm-row justify-center mt-5"
                >
                  <v-col
                    cols="12"
                    sm="8"
                    md="7"
                    class="rounded-lg pa-2 pa-sm-6"
                  >
                    <!-- begin:: student codes -->
                    <v-row>
                      <v-col cols="12" sm="6">
                        <div class="grey lighten-4 rounded pa-3">
                          <div class="grey--text">Código de carnet</div>
                          <div class="text-h6 font-weight-medium">
                            {{ currentUserPersonalInfo.code }}
                          </div>
                        </div>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <div class="grey lighten-4 rounded pa-3">
                          <div class="grey--text">NIE</div>
                          <div class="text-h6 font-weight-medium">
                            {{ currentUserPersonalInfo.nie || "No disponible" }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12">
                        <div class="grey lighten-4 rounded pa-3">
                          <div class="grey--text">Correo institucional</div>
                          <div class="text-h6 font-weight-medium">
                            {{ currentUserPersonalInfo.email }}
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- end:: student codes -->

                    <!-- begin::student section, group and speciality -->
                    <v-row>
                      <v-col cols="12" sm="6">
                        <div class="grey lighten-4 rounded pa-3">
                          <div class="grey--text">Grado</div>
                          <div class="text-h6 font-weight-medium">
                            {{ currentUserPersonalInfo.grade }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <div class="grey lighten-4 rounded pa-3">
                          <div class="grey--text">Sección</div>
                          <div class="text-h6 font-weight-medium">
                            {{ currentUserPersonalInfo.section_group }}
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <div class="grey lighten-4 rounded pa-3">
                          <div v-if="!isFromTercerCiclo">
                            <div class="grey--text">Especialidad</div>
                            <div class="text-h6 font-weight-medium">
                              {{ currentUserPersonalInfo.speciality }}
                            </div>
                          </div>
                          <div v-else>
                            <div class="grey--text">Nivel académico</div>
                            <div class="text-h6 font-weight-medium">
                              Tercer Ciclo
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- begin::student section, group -->
                  </v-col>
                  <v-divider
                    vertical
                    inset
                    class="d-none d-sm-flex"
                  ></v-divider>
                  <!-- begin::rigth side info (future place for student picture) -->
                  <v-col
                    cols="12"
                    sm="4"
                    md="5"
                    class="d-flex justify-center flex-column align-center"
                  >
                    <div class="d-flex align-items-center flex-column mt-5">
                      <!-- <v-avatar v-if="hasPhoto" size="150" class="mb-4">
            <v-img :src="`${currentUserPersonalInfo.photo}`"></v-img>
          </v-avatar> -->
                      <v-img
                        width="140"
                        v-if="hasPhoto"
                        :src="`${currentUserPersonalInfo.photo}`"
                        :lazy-src="`${currentUserPersonalInfo.photo}`"
                        aspect-ratio="0.80"
                        class="grey lighten-2 rounded-lg mb-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <div v-else class="symbol symbol-100 mb-5">
                        <div
                          class="symbol-label font-size-h1 font-weight-bold bg-blue text-black"
                        >
                          {{ getFirstNameInitial }}
                        </div>
                      </div>
                      <div class="d-flex flex-column">
                        <a
                          href="#"
                          class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary text-center"
                        >
                          <span>{{ currentUserPersonalInfo.first_name }}</span>
                          <br />
                          <span>{{ currentUserPersonalInfo.last_name }}</span>
                        </a>
                      </div>
                    </div>
                  </v-col>
                  <!-- end::rigth side info (future place for student picture) -->
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
        <!-- end::Student Information Card-->

        <!-- begin:: student ensurance information card -->
        <v-sheet elevation="2" class="d-none pa-5 rounded-lg mb-8">
          <v-row class="d-flex justify-center">
            <!-- begin::insurance company logo -->
            <v-col
              cols="12"
              class="ma-0 pa-2 d-flex justify-center align-center fixed-height"
            >
              <div
                :style="{ backgroundImage: `url(${insuranceLogo})` }"
                class="insurance-company-logo elevation-2 rounded-lg"
                alt="insurance company logo"
              ></div>
            </v-col>
            <!-- end::insurance company logo -->
            <!-- begin::informative broshure -->
            <v-col cols="12" sm="8" md="6" class="ma-0 pa-2">
              <v-sheet elevation="1" class="pa-5 rounded-lg">
                <p class="pa-0 ma-0 text-h5 text-center text-md-left">
                  Brochure informativo
                </p>
                <p class="text-body-1 text-center text-md-left">
                  información sobre seguro
                </p>
                <v-row>
                  <v-col class="d-flex justify-center justify-md-start">
                    <v-btn
                      outlined
                      rounded
                      :href="`seguro_accidentes/brochure-informativo.pdf`"
                      download
                    >
                      Descargar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <!-- end::informative broshure -->
            <!-- begin::ensurance card -->
            <v-col cols="12" sm="8" md="6" class="ma-0 pa-2">
              <v-sheet elevation="1" class="pa-5 rounded-lg">
                <p class="pa-0 ma-0 text-h5 text-center text-md-left">
                  Carnet de seguro
                </p>
                <p class="text-body-1 text-center text-md-left">
                  {{ currentUserPersonalInfo.full_name }}
                </p>
                <v-row>
                  <v-col class="d-flex justify-center justify-md-start">
                    <v-btn
                      outlined
                      rounded
                      :href="`seguro_accidentes/${currentUserPersonalInfo.code}.pdf`"
                      download
                    >
                      Descargar carnet
                    </v-btn>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <!-- end::ensurance card -->
          </v-row>
        </v-sheet>
        <!-- end:: student ensurance information card -->
      </div>
    </div>
    <!-- end::Card -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "StudentProfile",
  title: "Mi información | PE ITR",
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Información personal", route: "/profile/student_profile" },
      { title: "Datos de estudiante", route: "student_profile" },
    ]);
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isFromTercerCiclo() {
      if (!("speciality_id" in this.currentUserPersonalInfo)) {
        return false;
      }
      return this.currentUserPersonalInfo.speciality_id == 8;
    },
    insuranceLogo() {
      return process.env.BASE_URL + "media/logos/logo-aseguradora.jpeg";
    },
    hasPhoto() {
      return !!this.currentUserPersonalInfo.photo;
    },
    getFirstNameInitial() {
      if ("first_name" in this.currentUserPersonalInfo) {
        return this.currentUserPersonalInfo.first_name.charAt(0).toUpperCase();
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 2000px) {
  .insurance-company-logo {
    width: 35%;
  }
}

@media (max-width: 1000px) {
  .insurance-company-logo {
    width: 40%;
  }
}

@media (max-width: 800px) {
  .insurance-company-logo {
    width: 60%;
  }
}

@media (max-width: 600px) {
  .insurance-company-logo {
    width: 100%;
  }
}

.insurance-company-logo {
  aspect-ratio: 2/1;
  background-position: center;
  background-repeat: no-repeat, repeat;
  background-size: cover;
}

.red-pdf {
  background-color: #d93025 !important;
}

.score-sheet-card-small {
  display: none;
}

@media (max-width: 450px) {
  .score-sheet-card-large {
    display: none;
  }

  .score-sheet-card-small {
    display: block;
  }
}
</style>
